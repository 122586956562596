import React, { Component, useState } from 'react';
import { NodeComponent } from '../../Node/Node';
import TreeViewComponent from '../../TreeView/TreeViewComponent';
import { EventNames } from '../../../utils/enums/EventNames';
import { ActionsTabTypes } from '../../../utils/enums/ActionsTabTypes';
import { ViewTypeTabName } from '../../../utils/enums/ViewTypeTabName';
import { DataTypeTabNames } from '../../../utils/enums/DataTypeTabNames';

interface ActionsTabComponentState {
  activeLinkId: string;
}

export default class ActionsTabComponent extends Component<{}, ActionsTabComponentState> {
  
  expanded: any;  

  constructor(props: any) {
    super(props);
    this.state = { 
      activeLinkId: ""
    }
  }

  // componentDidMount(): void {
  //   this.setActiveItemSidebar("wellheads");
  // }

  raiseChangeTabEvent = (tabName: string, extraInfo: any) => {
    this.setClassActive(extraInfo);
    const customEvent = new CustomEvent(EventNames.changeTabEvent, { detail: {tabName, extraInfo} });
    document.dispatchEvent(customEvent);
    this.setActiveItemSidebar(extraInfo);
  }

  setActiveItemSidebar = (item: string) => {
    document.querySelectorAll('[id^="CheckboxTreeData"],[id*=" CheckboxTreeData"]')?.forEach((el:any) => el.parentElement.classList.remove('active'))
    document.querySelector(`#CheckboxTreeData-${item}`)?.parentElement?.classList.add('active');
  }

  getDataManagementNodes = () : NodeComponent[] => {    
    return [
      new NodeComponent('importdata', 'Import data', () => alert('Import data'), undefined, [
        new NodeComponent(ActionsTabTypes.WELLHEADS, <>&nbsp;&nbsp;Well heads</>, () => {this.raiseChangeTabEvent(ViewTypeTabName.PROCESS, ActionsTabTypes.WELLHEADS)}, undefined, undefined),
        new NodeComponent(ActionsTabTypes.WELLLOGS, <>&nbsp;&nbsp;Well logs</>, () => {this.raiseChangeTabEvent(ViewTypeTabName.PROCESS, ActionsTabTypes.WELLLOGS)}, undefined, undefined),
        new NodeComponent(ActionsTabTypes.WELLPATHS, <>&nbsp;&nbsp;Well paths</>, () => {this.raiseChangeTabEvent(ViewTypeTabName.PROCESS, ActionsTabTypes.WELLPATHS)}, undefined, undefined),
        new NodeComponent(ActionsTabTypes.WELLMARKERS, <>&nbsp;&nbsp;Well markers</>, () => {this.raiseChangeTabEvent(ViewTypeTabName.PROCESS, ActionsTabTypes.WELLMARKERS)}, undefined, undefined),
        new NodeComponent(ActionsTabTypes.WELLSETS, <>&nbsp;&nbsp;Well sets</>, () => {}, undefined, undefined),
        // new NodeComponent(ActionsTabTypes.TIMEMARKERS, <>&nbsp;&nbsp;Time markers</>, () => {}, undefined, undefined),
        // new NodeComponent(ActionsTabTypes.CULTURALDATA, <>&nbsp;&nbsp;Cultural data</>, () => {}, undefined, undefined),
      ]),
      new NodeComponent(ActionsTabTypes.EXPORTDATA, ViewTypeTabName.EXPORT_DATA, () => {}, undefined, undefined),
      new NodeComponent(ActionsTabTypes.REMOVEDATA, ViewTypeTabName.REMOVE_DATA, () => {}, undefined, undefined),
    ]
  }

  getRunWellCorrelationNodes = () : NodeComponent[] => {    

    return [
      new NodeComponent(ViewTypeTabName.AUTOMATED_CORRELATION, ViewTypeTabName.AUTOMATED_CORRELATION, () => {this.raiseChangeTabEvent(ViewTypeTabName.PROCESS, ActionsTabTypes.AUTOMATED_CORRELATION)}, undefined, undefined),
      // new NodeComponent('stepwisecorrelation', 'Stepwise correlation', () => alert('Stepwise correlation'), undefined, undefined)
    ]
  }

  setClassActive = (target:any) => {    
    this.setState({"activeLinkId": target});
  };

  render() {
    var containerFluidStyle = {
      width: '100%',
      paddingLeft: '0',
      paddingRight: '0',
    };

    var secondRowStyle = {
      paddingTop: '20px',
      '& .row:last-child': {
        paddingTop: '20px', // Adjust the value as needed
      },
    }; 

    return (
      <>
        <div className="container-fluid" style={containerFluidStyle}>
          <div className="row">
            <div className="col-xs-12">            
              <div className='sidebar-section' style={{'paddingBottom': '5px'}}>                                  
                <details>
                  <summary className='title'><span>Data Management</span> <i className="arrow down"></i></summary>
                    <div>
                      <details>
                        <summary className='subtitle'>Import Data</summary>
                        <ul>
                          <li className={this.state.activeLinkId == ActionsTabTypes.WELLHEADS ? 'active' : ''} onClick={()=>{this.raiseChangeTabEvent(ViewTypeTabName.PROCESS, ActionsTabTypes.WELLHEADS)}}>
                            Heads
                          </li>
                          <li className={this.state.activeLinkId == ActionsTabTypes.WELLLOGS ? 'active' : ''} onClick={()=>{this.raiseChangeTabEvent(ViewTypeTabName.PROCESS, ActionsTabTypes.WELLLOGS)}}>
                            Logs
                          </li>
                          <li className={this.state.activeLinkId == ActionsTabTypes.WELLPATHS ? 'active' : ''} onClick={()=>{this.raiseChangeTabEvent(ViewTypeTabName.PROCESS, ActionsTabTypes.WELLPATHS)}}>
                            Paths
                          </li>
                          <li className={this.state.activeLinkId == ActionsTabTypes.WELLMARKERS ? 'active' : ''} onClick={()=>{this.raiseChangeTabEvent(ViewTypeTabName.PROCESS, ActionsTabTypes.WELLMARKERS)}}>
                            Markers
                          </li>
                        </ul>
                      </details>

                      <details>
                        <summary className='subtitle'>Export Data</summary>
                        <ul>
                          <li>
                            Heads
                          </li>
                          <li className={this.state.activeLinkId == ActionsTabTypes.WELLLOGSEXPORT ? 'active' : ''} onClick={()=>{this.raiseChangeTabEvent(ViewTypeTabName.PROCESS, ActionsTabTypes.WELLLOGSEXPORT)}}>
                            Logs
                          </li>
                          <li>
                            Paths
                          </li>
                          <li>
                            Markers
                          </li>
                        </ul>
                      </details>

                      <details>
                        <summary className='subtitle'>Remove Data</summary>
                        <ul>
                          <li>
                            Heads
                          </li>
                          <li>
                            Logs
                          </li>
                          <li>
                            Paths
                          </li>
                          <li>
                            Markers
                          </li>
                        </ul>
                      </details>

                    </div>                    
                  </details>
              </div>                  
    
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">            
              <div className='sidebar-section' style={{'paddingBottom': '5px'}}>                                  
                <details>
                  <summary className='title'><span>Run Well Correlation</span> <i className="arrow down"></i></summary>
                  <div className={this.state.activeLinkId == ActionsTabTypes.AUTOMATED_CORRELATION ? 'subtitle active' : 'subtitle'} onClick={()=>{this.raiseChangeTabEvent(ViewTypeTabName.PROCESS, ActionsTabTypes.AUTOMATED_CORRELATION)}}>
                    <span>Automated Correlation</span>
                  </div>                 
                </details>
              </div>
            </div>
          </div>

          {/* <div className="row" style={secondRowStyle}>
            <div className="col-xs-12">
              <TreeViewComponent children={this.getRunWellCorrelationNodes()} rootLabel="Run well correlation (advanced)" rootValue="datamanagement"/>
            </div>
          </div> */}
        </div>
      </>      
    );
  }
}
