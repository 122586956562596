export enum ActionsTabTypes {
    WELLHEADS = 'wellheads',
    WELLLOGS = 'welllogs',
    WELLLOGSEXPORT = 'welllogsexport',
    WELLPATHS = 'wellpaths',
    WELLMARKERS = 'wellmarkers',
    WELLSETS = 'wellsets',
    TIMEMARKERS = 'timemarkers',
    CULTURALDATA = 'culturaldata',
    EXPORTDATA = 'exportdata',
    REMOVEDATA = 'removedata',
    AUTOMATED_CORRELATION = 'Automated correlation'
}