import React, { Component } from 'react';

interface SelectItemsListComponentProps {
    listItems: string[];
    title: string,
}

interface SelectItemsListComponentState {
    availableItemsList: any[];
    filteredItemsList: any[],
    
    isSelected: any[];
    selectedItemsList: any[];
    filteredSelectedItemsList: any[],

    filterAvailableText: string;
    filterSelectedText: string;
}

export default class SelectItemsListComponent extends Component<SelectItemsListComponentProps, SelectItemsListComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            availableItemsList: this.props.listItems,
            filteredItemsList: this.props.listItems,
            selectedItemsList: [],
            isSelected: [],
            filteredSelectedItemsList: [],
            filterAvailableText: '',
            filterSelectedText: '',
        };
    }

    filterItems = (type: string, itemsList: any, filterText: any) => {
        const filteredItemsList = itemsList.filter(function (str:string) { return str.includes(filterText); })

        if(type == "available"){
            this.setState({filterAvailableText: filterText, filteredItemsList: filteredItemsList})        
        }

        if(type == "selected"){
            this.setState({filterSelectedText: filterText, filteredSelectedItemsList: filteredItemsList})        
        }
        
    };

    handlerSelectChangeEvent = (e:any) =>{
        const selectedItems = Array.from(e.target.options).filter(function (option:any) {
            return option.selected; 
        }).map(function (option:any) {
            return option.value;
        });
        
        this.setState({isSelected: selectedItems})      
    }

    handlerAddButton = () => {
        const availableItemsList = this.state.availableItemsList;
        const selectedItemsList = this.state.selectedItemsList;
        const newSelectedItems = this.state.isSelected.filter( ( el ) => !selectedItemsList.includes( el ) );

        newSelectedItems.map(item => {
            if(!selectedItemsList.includes(item)){
                selectedItemsList.push(item)
            }

            if(availableItemsList.includes(item)){
                availableItemsList.splice(availableItemsList.indexOf(item), 1)
            }
        })

        this.setState({availableItemsList: availableItemsList.sort(), selectedItemsList: selectedItemsList.sort(), filteredItemsList:availableItemsList.sort(), filteredSelectedItemsList:selectedItemsList.sort(), filterAvailableText: '', filterSelectedText: ''}, () => this.filterItems("available", this.state.availableItemsList,this.state.filterAvailableText))
    }

    handlerAddAllButton = () => {
        let availableItemsList = this.state.availableItemsList;
        let selectedItemsList = this.state.selectedItemsList;
        let itemsToRemove:any[] = []
        
        availableItemsList.map(item => {
            if(!selectedItemsList.includes(item)){
                selectedItemsList.push(item)
                itemsToRemove.push(item)
            }            
        })

        itemsToRemove.map(item => {           
            if(availableItemsList.includes(item)){
                availableItemsList.splice(availableItemsList.indexOf(item), 1)
            }
        })        

        this.setState({availableItemsList: availableItemsList.sort(), selectedItemsList: selectedItemsList.sort(), filteredItemsList:availableItemsList.sort(), filteredSelectedItemsList:selectedItemsList.sort(), filterAvailableText: '', filterSelectedText: ''}, () => this.filterItems("available", this.state.availableItemsList,this.state.filterAvailableText))
    }

    handlerRemoveButton = () => {
        const availableItemsList = this.state.availableItemsList;
        const selectedItemsList = this.state.selectedItemsList;
        const newSelectedItems = this.state.isSelected.filter( ( el ) => !availableItemsList    .includes( el ) );

        newSelectedItems.map(item => {
            if(!availableItemsList.includes(item)){
                availableItemsList.push(item)
            }

            if(selectedItemsList.includes(item)){
                selectedItemsList.splice(selectedItemsList.indexOf(item), 1)
            }
        })

        this.setState({availableItemsList: availableItemsList.sort(), selectedItemsList: selectedItemsList.sort(), filteredItemsList:availableItemsList.sort(), filteredSelectedItemsList:selectedItemsList.sort(), filterAvailableText: '', filterSelectedText: ''}, () => this.filterItems("available", this.state.availableItemsList,this.state.filterAvailableText))
    }

    handlerRemoveAllButton = () => {
        let availableItemsList = this.state.availableItemsList;
        let selectedItemsList = this.state.selectedItemsList;
        let itemsToRemove:any[] = []
        
        selectedItemsList.map(item => {
            if(!availableItemsList.includes(item)){
                availableItemsList.push(item)
                itemsToRemove.push(item)
            }            
        })

        itemsToRemove.map(item => {           
            if(selectedItemsList.includes(item)){
                selectedItemsList.splice(selectedItemsList.indexOf(item), 1)
            }
        })        

        this.setState({availableItemsList: availableItemsList.sort(), selectedItemsList: selectedItemsList.sort(), filteredItemsList:availableItemsList.sort(), filteredSelectedItemsList:selectedItemsList.sort(), filterAvailableText: '', filterSelectedText: ''}, () => this.filterItems("available", this.state.availableItemsList,this.state.filterAvailableText))
    }

    render(): React.ReactNode {
        return (
            <div className='selectItems'>
                <div className='left'>
                    <h3>Available {this.props.title}</h3>
                    <div>
                        <input key={'filterAvailableItems'} value={this.state.filterAvailableText} className="filter-text" placeholder="Search..." type="text"  onChange={(event) => this.filterItems("available", this.state.availableItemsList, event.target.value)}/>
                        <select className='styled-scrollbars' multiple onChange={(event) => this.handlerSelectChangeEvent(event)}>
                            {
                                this.state.filteredItemsList.map((item)=>{
                                    return (<option key={item} value={item}>{item}</option>)
                                })
                            }                    
                        </select>
                    </div>
                    
                </div>
                <div className='buttons center'>
                    <button onClick={this.handlerAddButton}>&#62;</button>
                    <button onClick={this.handlerAddAllButton}>&#62;&#62;&#62;</button>
                    <button onClick={this.handlerRemoveButton}>&#60;</button>
                    <button onClick={this.handlerRemoveAllButton}>&#60;&#60;&#60;</button>
                </div>
                <div className='right'>
                    <h3>Selected {this.props.title}</h3>
                    <div>
                        <input key={'filterSelectedItems'} value={this.state.filterSelectedText} className="filter-text" placeholder="Search..." type="text"  onChange={(event) => this.filterItems("selected", this.state.selectedItemsList, event.target.value)}/>                        
                        <select className='styled-scrollbars' multiple onChange={(event) => this.handlerSelectChangeEvent(event)}>
                            {
                                this.state.filteredSelectedItemsList.map((item)=>{
                                    return (<option key={item} value={item}>{item}</option>)
                                })
                            }
                        </select>
                    </div>                    
                </div>
            </div>
        )
    }
}


