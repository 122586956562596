import React, { Component } from 'react';
import SelectItemsListComponent from '../../SelectItemsList/SelectItemsList';
import SelectFormatComponent from '../../SelectFormat/SelectFormat';

interface WellLogsExportComponentState {
    fileList: any[];
    uploading: boolean;
    newWellNames:any;
    activeTab:string;
    newWellsNames: any;
    dataList: any[];
}

interface WellLogsExportProps{
    fileList: any[];
}

interface WellLogsExportComponentState {
    fileList: any[];
    uploading: boolean;
    newWellNames:any;
    activeTab:string;
    newWellsNames: any;
    dataList: any[];
}

const CONTEXT = 'wellLogs';

enum TabNames{
    selectData = "Select Wells",
    selectFormat = "Select Format"
}

const tabOrder: string[] =[
    TabNames.selectData,
    TabNames.selectFormat,
]

export default class WellLogsExportComponent extends Component<{}, WellLogsExportComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {
          activeTab: TabNames.selectData,
          fileList: [],
          uploading: false,
          newWellNames:[],
          dataList:[],
          newWellsNames:{},
        };
    }
    
    setTab = (tabName:string) => {
        this.setState({activeTab:tabName});
    }

    tabHeader = () =>{
        return (
            <div className="process-nav">
                {tabOrder.map((currentTab,idx)=>{
                    return (
                        <div className={`col k-tab ${currentTab==this.state.activeTab?"activeTab":""}`} onClick={()=>{this.setTab(currentTab)}}>
                            {currentTab}
                        </div>
                    )
                })}
            </div>
        )
    }

    tabsView = () =>{
        return (
          <section id="tabs">
            <div style={{textAlign: "center"}}>          
              {this.tabHeader()}
            </div> 
            <div className="tab-content">
                <div
                className={`tab-pane fade show ${this.state.activeTab === TabNames.selectData ? 'active' : ''}`}          
                role="tabpanel"
                style={{textAlign:"center"}}    
                >
                    <SelectItemsListComponent listItems={["Item 1","Item 2","Item 3","Item 4"]} title='Wells'/>
              </div>              
              <div
                className={`tab-pane fade show ${this.state.activeTab === TabNames.selectFormat ? 'active' : ''}`}          
                role="tabpanel"          
              >
                <SelectFormatComponent listHeaderOptions={[]}/>
              </div>
            </div>
          </section>
        )
    }
      
    
    render(): React.ReactNode {
        return (
            <>
                {this.tabsView()}
            </>
        )
    }
}