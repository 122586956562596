import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import ToastHelper from '../../../utils/helpers/ToastHelper';

import { getMultiwellPlot, getPairwise, saveNewRGTMarker } from '../../../services/correlationService';
import { EventNames } from '../../../utils/enums/EventNames';
import { InfiniteProgressBar } from '../../ProgressBar/ProgressBarInfinte';
import { refreshDataTab } from '../DataTabComponent/DataTabComponent';
// import InpetuHelper from '../../../utils/helpers/InpetuHelper';
// import { MultiWellCorrelationWidget } from '../../Incharts/Original/well/MultiWellCorrelationWidget';
import KogCharts from '../../KogCharts/KogCharts';
import { getUserConfs } from '../../../services/projectService';
import { addNewMarkersData, getMarkersData } from '../../../services/stratigraphicService';
import { getLogs, getTracksLogs, wellMetadata } from '../../../services/wellService';


const well_depth = [
    {key:"RGT", show:"RGT"},
    {key:"MD", show:"MD"},
    {key:"TVD", show:"TVD"},
    {key:"TVDSS", show:"TVDSS"}
];

const depthsNames = [
    well_depth[0].key,
    well_depth[1].key,
    well_depth[2].key,
    well_depth[3].key
]

/**
 * Event to change Correlation data in Correlation View Tab.
 */
export function changeCorrelationData(pairwiseId:number, pairwiseName:string, multiwellId:number, multiwellName:string){
    const customEvent = new CustomEvent(EventNames.changeCorrelationViewDataEvent, { detail: {
        pairwiseId: pairwiseId,
        pairwiseName: pairwiseName,
        multiwellId: multiwellId,
        multiwellName: multiwellName,
    } 
    });
    document.dispatchEvent(customEvent);
}

interface CorrelationDataset{
    name: string,
    distance: number,
    wellsNames: string[],
    norms: {[index: string]:any},
    maxLags: number,
    logNames: string[],
    boundaries: any,
    barPlot: string[],
    curvePlot: string[],
    correlationResolution: number,
}

interface CorrelationsViewTabProps{
}

interface newWellMarkerDialog{
    title:string;
    wellName:string;
    depth:number;
  }

interface CorrelationsViewTabState {
    loading: boolean,
    correlationData: CorrelationDataset,
    rgt: number,
    loadingPlots:boolean,
    savingMarker:boolean,
    // firstRGTVisualization: boolean,
    markerName: string,
    pairwiseId: number,
    pairwiseName: string,
    multiwellId: number,
    multiwellName: string,
    // impetu_plot: any,
    correlationCharts: any[],
    originalData: any[],
    plotConfig: any,
    showNewMarkerDialog:boolean,
    newMarkerDialogParameters:newWellMarkerDialog,
    prevExistentMarkerDialog:boolean,
    viewInDepthSacel:string,
    wellMarkersData:any
}

export default class CorrelationsViewTabComponent extends Component<CorrelationsViewTabProps, CorrelationsViewTabState> {

    changeDataEvent:string = EventNames.changeCorrelationViewDataEvent;

    markerName:any

    constructor(props: any) {
        super(props);

        this.state = {
            loading:false,
            rgt:0,
            loadingPlots:false,
            // firstRGTVisualization:false,
            savingMarker:false,
            markerName:"",
            pairwiseId: 0,
            pairwiseName: "",
            multiwellId: 0,
            multiwellName: "",
            // impetu_plot:{},
            correlationCharts:[],
            originalData:[],
            plotConfig:{
                tracksLineWidth: 1,
                tracksWidth: 200,
                tracksGap: 10,
                tracksHeight: 700,
                tracksVerticalScale: true,
                tracksShowZoom: true,
            },
            correlationData: {
                name: "",
                distance: 10000,
                wellsNames: [],
                norms: {},
                maxLags: 200,
                logNames: [],
                boundaries: {},
                barPlot: [],
                curvePlot: [],
                correlationResolution: 1,
            },
            showNewMarkerDialog:false,
            newMarkerDialogParameters:{
              title:"",
              wellName:"",
              depth:0
            },
            prevExistentMarkerDialog:false,
            viewInDepthSacel:depthsNames[0],
            wellMarkersData:{}
        }
    }

    getPlotData = () =>{
        let newParams:any[] = []
        // let markers = getMarkersData();
        wellMetadata().then((response)=>{
            this.state.correlationData.wellsNames.forEach((wellName:string)=>{
                let logs:string[] = []
                logs = logs.concat(Object.keys(response[wellName.split(" - ")[0]].curves.versions[0].curves_info).filter((curveName)=>this.state.correlationData.logNames.includes(curveName)))
                if( logs.length > 0 && response[wellName.split(" - ")[0]].curves && response[wellName.split(" - ")[0]].curves.versions && response[wellName.split(" - ")[0]].curves.versions.length > 0 ){
                    newParams.push({
                        version: response[wellName.split(" - ")[0]].curves.versions[0].id,
                        wellname: wellName.split(" - ")[0],
                        logs:logs,
                        markers_list:[],
                        depth_reference:this.state.viewInDepthSacel
                    })
                }
            })
            if( newParams.length > 0 ){
                getTracksLogs(newParams).then((result: any) => {
                    this.setState({
                        originalData:result.logs_data,
                        loading:false,
                        loadingPlots:false,
                        savingMarker:false,
                    })
                    // markers.then((markersResponse)=>{
                    //     if( this.state.viewInDepthSacel != "RGT" ){
                    //         let error = false;
                    //         let kogChartsData = result.logs_data.map((well:any,wellIdx:number)=>{
                    //             let depthReference = well.curves.filter((curve:any)=> curve.name == this.state.viewInDepthSacel)[0]
                    //             if( !depthReference ){
                    //                 error = true
                    //                 return;
                    //             }
                    //             let properties = well.curves.filter((curve:any)=> !depthsNames.includes(curve.name))
                    //             let currentMarkers:any = [];
                    //             if(markersResponse.markers_data[well.well] && Object.keys(markersResponse.markers_data[well.well]).length > 0){
                    //               currentMarkers = Object.keys(markersResponse.markers_data[well.well]).map((markerName)=>{
                    //                 return { name:markerName, depth: markersResponse.markers_data[well.well][markerName] }
                    //               })
                    
                    //             }
                    //             let groupData = properties.map((curve:any,curveIdx:number)=>{
                    //               const curveMin = Math.min(...curve.values.filter((data:any)=> data!=null));
                    //               const curveMax = Math.max(...curve.values)
                    //               return {
                    //                 name:curve.name,
                    //                 data:curve.values.map((value:number,idx:number)=>{return [value, depthReference.values[idx]]}),
                    //                 min:curveMin,
                    //                 max:curveMax,
                    //                 markerLine: currentMarkers
                    //               }
                    //             })
                    //             return {
                    //               name: well.well,
                    //               groupData:groupData,
                    //               depthReference:depthReference
                    //             }
                    //           })
                    //           if(!error){
                    //               this.setState({
                    //                   correlationCharts:[],
                    //                   loading:false,
                    //                   loadingPlots:false,
                    //                   savingMarker:false,
                    //               },()=>{
                    //                   this.setState({originalData:kogChartsData})
                    //               })
                    //           }
                    //           else{
                    //             ToastHelper.error(`${this.state.viewInDepthSacel} not found in Well Data`, ()=>{})
                    //             this.setState({loading:false, loadingPlots:false})
                    //           }
                    //     }
                    // })
                })
            }
        })
    }

    createInitialColors = () => {
        wellMetadata().then((response)=>{
            let tracks_confs = this.state.plotConfig
            if(tracks_confs){
                Object.keys(response).forEach((wellName:any, idx)=>{
                    const well_name = `${wellName} - Resolution ${response[wellName].curves?.versions[0].name}`
                    const color = this.createColor();
                    if(!tracks_confs.colorsData[well_name]){
                        tracks_confs.colorsData[well_name] = {line: color, fill: color}
                    }
                })
                this.setState({plotConfig:tracks_confs})
            }
        })
    }

    createColor = () => {
        // The available hex options
        let hex = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    
        /**
         * Randomly shuffle an array
         * https://stackoverflow.com/a/2450976/1293256
         */
        let shuffle = (hex:any) => {
    
            let currentIndex = hex.length;
            let temporaryValue, randomIndex;
    
            // While there remain elements to shuffle...
            while (0 !== currentIndex) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
    
                // And swap it with the current element.
                temporaryValue = hex[currentIndex];
                hex[currentIndex] = hex[randomIndex];
                hex[randomIndex] = temporaryValue;
            }
    
        };
    
        /**
         * Create a six-digit hex color
         */
        let hexColor = () => {
            // Create the color
            let color = '#';
    
            // Shuffle the hex values and append
            for (let i = 0; i < 6; i++) {
                shuffle(hex);
                color += hex[0];
            }
    
            return color;
        };
    
        // Return the color string
        return hexColor();
    }

    updateTracksConf(){
        getUserConfs().then((userConfs)=>{
            let displayConf:any = this.state.plotConfig;
            let depthScale = "RGT";
            if(userConfs.plotView){
                const currentConf = userConfs.plotView.plotViewConf;
                displayConf = {
                    tracksLineWidth: parseInt(currentConf.trackLinesThickness),
                    tracksWidth: parseInt(currentConf.trackWidth),
                    tracksGap: parseInt(currentConf.trackgap),
                    tracksHeight: parseInt(currentConf.trackHeight),
                    tracksVerticalScale: currentConf.trackShowVerticalScale=="true"?true:false,
                    tracksShowZoom: currentConf.trackShowZoomBar=="true"?true:false,
                }
                if(userConfs.wellTracksColor){
                    displayConf.colorsData = userConfs.wellTracksColor;
                }
                depthScale = currentConf.correlationDepth;
                // if(depthScale != "RGT"){
                //     this.setState({viewInDepthSacel:depthScale},()=>{
                        
                //     })
                // }
            }
            this.setState({plotConfig:displayConf, viewInDepthSacel:depthScale}, ()=>{
                this.createInitialColors();
                this.switchData()
            });
        });
    }

    componentDidMount() {
        document.addEventListener(EventNames.refreshWellTrackPlotEvent, this.changeTrackConfs as EventListener);
        document.addEventListener(this.changeDataEvent, this.changeCorrelationData);
    }
    
    componentWillUnmount() {
        document.removeEventListener(EventNames.refreshWellTrackPlotEvent, this.changeTrackConfs as EventListener);
        document.removeEventListener(this.changeDataEvent, this.changeCorrelationData);
    }

    changeTrackConfs = (event: CustomEvent) => {
        if (event.type !== EventNames.refreshWellTrackPlotEvent){
          return;
        }
        var newConfigs = event.detail.settings;
        const displayConf = newConfigs.wellViewState;
        let depthScale = "RGT";
        this.setState({
            // impetu_plot: plot.getInpetuFormat(),
            loadingPlots:true,
        }, ()=>{
            // this.updateTracksConf()
        });
        var plotConfig:any = {
            tracksLineWidth: parseInt(displayConf.trackLinesThickness),
            tracksWidth: parseInt(displayConf.trackWidth),
            tracksGap: parseInt(displayConf.trackgap),
            tracksHeight: parseInt(displayConf.trackHeight),
            tracksVerticalScale: displayConf.trackShowVerticalScale,
            tracksShowZoom: displayConf.trackShowZoomBar,
        }
        if(newConfigs.wellTracksColor){
        plotConfig.colorsData = newConfigs.wellTracksColor;
        }
        depthScale = displayConf.correlationDepth;
        // if(depthScale != "RGT"){
        //     this.setState({viewInDepthSacel:depthScale},()=>{
        //         this.switchData()
        //     })
        // }
        this.setState({plotConfig:plotConfig, viewInDepthSacel:depthScale,},()=>{
            this.createInitialColors();
            this.switchData()
        })
    }

    /**
     * Change correlation data Event.
     * @param event Event
     */
    changeCorrelationData = (event: any) => {
        if (event.type !== this.changeDataEvent){
          return;
        }
        var dataset = event.detail;
        this.setState({
            loading:true,
            pairwiseId: dataset.pairwiseId,
            pairwiseName: dataset.pairwiseName,
            multiwellId: dataset.multiwellId,
            multiwellName: dataset.multiwellName
        })
        const pairwiseData = getPairwise(dataset.pairwiseName);
        pairwiseData.then((response)=>{
            let multiwellModel = response.multiwell_models[Object.keys(response.multiwell_models)[0]];
            const logsNames: string[] = Array.from(new Set(response.log_names));
            let correlationData:CorrelationDataset = {
                name: dataset.multiwellName,
                distance: 0,
                wellsNames: response.wells,
                norms: response.norms,
                maxLags: response.max_lags,
                logNames: logsNames,
                boundaries: response.boundaries,
                barPlot: multiwellModel.bar_plots,
                curvePlot: multiwellModel.curves_plots,
                correlationResolution: response.correlation_resolution,
            };
            this.setState({correlationData:correlationData},()=>{
                this.reloadPlotData();
            });
        }).catch((error)=>{
            this.setState({loading:false});
        })
    }

    reloadPlotData =() =>{
        this.switchData()
    }

    saveNewMarker = () =>{
        this.setState({savingMarker:true, correlationCharts:[]})
        if(this.state.viewInDepthSacel == "RGT"){
            const dataset = {
                pairwise_id:this.state.pairwiseId,
                rgt:this.state.rgt,
                marker_name: this.state.markerName
            }
            const newMarkerResponse = saveNewRGTMarker(dataset, this.state.multiwellName);
            newMarkerResponse.then((response)=>{
                // this.getMultiwellPlots();
                refreshDataTab();
                
                this.setState({savingMarker:false},()=>{
                    this.reloadPlotData();
                });
            })
        }
        else{
            let dataset: {[index: string]:any} = {};
            const markers_data = this.state.wellMarkersData;
            Object.keys(markers_data).forEach(wellName=>{
                dataset[wellName] = {};
                dataset[wellName][this.state.markerName] = markers_data[wellName]
            })
            addNewMarkersData(dataset).then((response)=>{
            if(response.message == "New markers was successful added"){
                ToastHelper.success(`Add new Well Marker '${this.markerName}' was successful!`);
            }
            else{
                ToastHelper.error("Error when add new well marker. Please, contact your administrator." , ()=>{
                console.log(response);
                });
            }
            this.setState({savingMarker:false},()=>{
                this.reloadPlotData();
            });
    })
        }
    }

    /**
     * Change RGT value.
     * @param value New RGT value.
     */
    changeRGT = (value:number) =>{
        this.setState({rgt:value});
    }

    /**
     * Change RGT value.
     * @param value New RGT value.
     */
    setMarkerName = (value:string) =>{
        this.setState({markerName:value});
    }

    /**
     * Preview new Marker by RGT in plot event.
     */
    // previewNewMarkerEvent = () =>{
    //     let tracksData = this.state.correlationCharts;
    //     tracksData = tracksData.map((wellTracks)=>{
    //         wellTracks.groupData.map((groupData:any)=>{
    //             let selectMarkers = groupData.markerLine.filter((marker:any) => marker.name == "New Marker");
    //             if(selectMarkers.length>0){
    //                 selectMarkers[0].depth = this.state.rgt;
    //             }
    //             else{
    //                 groupData.markerLine.push({name:"New Marker", depth:this.state.rgt});
    //             }
    //             return groupData;
    //         })
    //         return wellTracks
    //     })
    //     this.setState({correlationCharts:tracksData});
    // }

    openAddNewWellMarkerDialog = (params:any) =>{
        if( this.state.viewInDepthSacel == "RGT"){
            const wellName = params.currentGroupName
            const wellDepths = params.clickData[wellName]
            const logsNames = Object.keys(wellDepths);
            const wellDepth = wellDepths[logsNames[0]].currentY.toFixed(2)
            this.setState({
              showNewMarkerDialog:true,
              newMarkerDialogParameters:{
                title:wellName,
                depth:parseFloat(wellDepth),
                wellName:logsNames.toString()
              }
            })
        }
        else{
            let new_data: {[index: string]:any} = {}
            const wells_names = Object.keys(params.clickData)
            let wells_names_to_show:string[] = []
            Object.keys(params.clickData).forEach((well_name)=>{
                const curve_names = Object.keys(params.clickData[well_name])
                new_data[well_name] = params.clickData[well_name][curve_names[0]].currentY
                wells_names_to_show.push(`${well_name} add in ${params.clickData[well_name][curve_names[0]].currentY.toFixed(2)}`)
            })
            this.setState({
                wellMarkersData:new_data,
                showNewMarkerDialog:true,
                newMarkerDialogParameters:{
                    title:"Add new Wells markers?",
                    depth:0.0,
                    wellName: wells_names_to_show.join(",")
                }
            })
        }
      }

    checkPreviousWellMarkers = () =>{
        const markersData = getMarkersData().then((response)=>{
          if(response.markers_names.map((markerName:string)=>markerName.toUpperCase()).includes(this.markerName.toUpperCase())){
            this.setState({prevExistentMarkerDialog:true})
          }
          else{
            this.sendNewWellMarker()
          }
        })
      }

    sendNewWellMarker = () =>{
        this.setState({markerName:this.markerName, rgt:this.state.newMarkerDialogParameters.depth}, ()=>{
            this.saveNewMarker()
        })
    }

    switchData = () =>{
        this.setState({loading:false});
       if( this.state.viewInDepthSacel == "RGT" && this.state.correlationData.name != "" ){
            const plot_data = getMultiwellPlot({
                pairwise_id:this.state.pairwiseId,
            },this.state.multiwellName).then((responsePlot)=>{
                this.setState({
                    originalData:[],
                    loading:false,
                    loadingPlots:false,
                    savingMarker:false,
                },()=>{
                    this.setState({
                        // plotConfig:displayConf,
                        correlationCharts:responsePlot,
                    });
                });
            });
       }
       else{
        this.getPlotData()
       }
    }

    dialogsBox = () =>{
        return(
            <>
                <div>
                <Dialog
                    open={this.state.prevExistentMarkerDialog}
                    onClose={()=>{ this.setState({prevExistentMarkerDialog:false}) }}
                    PaperProps={{
                    component:"form",
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) =>{
                        event.preventDefault();
                        this.setState({prevExistentMarkerDialog:false}, ()=>{
                        getMarkersData().then((response)=>{
                            this.markerName = response.markers_names.filter((markerName:string)=>markerName.toUpperCase() == this.markerName.toUpperCase())[0]
                            this.sendNewWellMarker()
                        })
                        })
                    }
                    }}
                >
                    <DialogTitle>Override well marker?</DialogTitle>
                    <DialogContent>
                    <DialogContentText>Marker '{this.markerName}'' Already exist in:</DialogContentText>
                    { this.state.newMarkerDialogParameters.wellName.split(",").map((wellName=>{
                            return <DialogContentText>{wellName}</DialogContentText>
                    })) }
                    <DialogContentText>this operation will override this data, you sure?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>{this.setState({prevExistentMarkerDialog:false})}}>No</Button>
                    <Button type='submit'>Yes, override it!</Button>
                    </DialogActions>
                </Dialog>
                <Dialog 
                    open={this.state.showNewMarkerDialog}
                    onClose={()=>{ this.setState({showNewMarkerDialog:false}) }}
                    PaperProps={{
                    component:"form",
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) =>{
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        this.markerName = formJson.markerName;
                        this.checkPreviousWellMarkers()
                        this.setState({showNewMarkerDialog:false})
                    }
                    }}
                >
                    <DialogTitle>Add new Well Marker</DialogTitle>
                    <DialogContent>
                        
                        {
                           this.state.viewInDepthSacel == "RGT" && (<DialogContentText>Set new marker name in {this.state.newMarkerDialogParameters.depth} {this.state.viewInDepthSacel} on wells:</DialogContentText>)
                        }
                        {
                           this.state.viewInDepthSacel != "RGT" && (<DialogContentText>Add new well markers on wells:</DialogContentText>)
                        }
                        { this.state.newMarkerDialogParameters.wellName.split(",").map((wellName=>{
                            return <DialogContentText>{wellName}</DialogContentText>
                        })) }
                        
                        <TextField 
                            autoFocus
                            required
                            id="markerName"
                            name="markerName"
                            label="Marker name"
                            type="text"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{this.setState({showNewMarkerDialog:false})}}>Cancel</Button>
                        <Button type='submit'>Save</Button>
                    </DialogActions>
                </Dialog>
                </div>
            </>
        )
    }


    render(): React.ReactNode {
        if(!this.state.loading){
            return (
                <>
                    {this.dialogsBox()}
                    <div className='row'>
                        <h1>{this.state.correlationData.name}</h1>
                    </div>
                    <div className='row'>
                        <div className='col-3'><div className='row'>Wells Names:</div>{this.state.correlationData.wellsNames.map((wellName)=>{return (<div className='row'>{wellName}</div>)})}</div>
                        <div className='col-2'><div className='row'>Norms:</div>{Object.keys(this.state.correlationData.norms).map((norm)=>{return (<div className='row'>{norm}: {this.state.correlationData.norms[norm]}</div>)})}</div>
                        <div className='col-2'><div className='row'>Logs Names:</div>{this.state.correlationData.logNames.map((logName)=>{return (<div className='row'>{logName}</div>)})}</div>
                        <div className='col-2'>Max Lags: {this.state.correlationData.maxLags}</div>
                        <div className='col-3'>Correlation Resolution: {this.state.correlationData.correlationResolution}</div>
                    </div>
                    {
                        this.state.loadingPlots && (
                            <>
                                <InfiniteProgressBar title='Loading plots Data...'/>
                            </>
                        )
                    }
                    {
                        this.state.savingMarker && (
                            <>
                                <InfiniteProgressBar title='Saving new Marker...'/>
                            </>
                        )
                    }
                    {
                        (!this.state.loadingPlots && !this.state.savingMarker && this.state.correlationCharts.length > 0) && (
                            <>
                            </>
                        )
                    }
                    <div className='row mt-3'>
                        <KogCharts
                            data={this.state.correlationCharts}
                            plotConfig={this.state.plotConfig}
                            trackLeftClickOptions={[
                                {displayName:"Add new Marker", blocked:false, callback:(data:any)=>{this.openAddNewWellMarkerDialog(data)}},
                            ]}
                        />
                        <KogCharts
                            data={this.state.originalData}
                            plotConfig={this.state.plotConfig}
                            trackLeftClickOptions={[
                                {displayName:"Add new Marker", blocked:false, callback:(data:any)=>{this.openAddNewWellMarkerDialog(data)}},
                            ]}
                        />
                    </div>
                </>
            )
        }
        else{
            return (
                <>
                    <InfiniteProgressBar title='Loading Correlation Data...'/>
                </>
            )
        }
    }
}