import React, { Component } from 'react';
import PreviewTableComponent from '../PreviewTable/PreviewTableComponent';
import { AttributeParam } from '../PreviewTable/TableOptions';

interface SelectFormatComponentProps{
    listHeaderOptions: AttributeParam[];
}

interface SelectFormatComponentState{

}

export default class SelectFormatComponent extends Component<SelectFormatComponentProps, SelectFormatComponent>{
    render(): React.ReactNode {
        return (
            <div className="formatOptions">
                <h4>Define the options of reading of the file:</h4>
                <div className="left">
                    <div>
                        <label>Delimiter</label><select><option value=""></option></select>
                    </div>
                    <div>
                        <label>Comment</label><select><option value=""></option></select>
                    </div>
                    <div>
                        <label>No Value</label><select><option value=""></option></select>
                    </div>
                </div>
                <div className="right">
                    <div>
                        <label>Qualifier</label><select><option value=""></option></select>
                    </div>
                    <div>
                        <label>Distance Unit</label><select><option value=""></option></select>
                    </div>
                    <div>
                        <label>Depth</label><select><option value=""></option></select>
                    </div>
                </div>
                
            </div>
        )        
    }
}