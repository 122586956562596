import React, { Component } from 'react';
import {ParserParam} from './ParserValues';
import { EventNames } from '../../utils/enums/EventNames';

interface ParserOptions{
    delimiter:ParserParam[];
    qualifier:ParserParam[];
    comment:ParserParam[];
    depthUnit:ParserParam[];
    depthType:ParserParam[];
    noValue:ParserParam[];
}

interface PaserProps{
    context:string;
    delimiter: string;
    qualifier: string;
    comment: string;
    startLine: number;
    depthUnit: string;
    depthType: string;
    noValue: string;
    options: ParserOptions;
}

interface PaserState{
    delimiter: string;
    qualifier: string;
    comment: string;
    startLine: number;
    depthUnit: string;
    depthType: string;
    noValue: string;
    timerToSend:any,
}

class ParserFieldsComponent extends Component<PaserProps, PaserState> {

    updatedParserEvent: string = EventNames.updatedParserEvent;

    constructor(props: any) {
        super(props);
        this.state = {
            delimiter: this.props.delimiter,
            qualifier: this.props.qualifier,
            comment: this.props.comment,
            startLine: this.props.startLine,
            depthUnit: this.props.depthUnit,
            depthType: this.props.depthType,
            noValue: this.props.noValue,
            timerToSend:null
        }
    }

    /**
     * Set delimiter value.
     * @param value new delimiter value.
     */
    setDelimiter(value:string){
        this.setState({delimiter:value});
        this.waitToSaveConfs();
    }

    /**
     * Set qualifier value.
     * @param value new qualifier value.
     */
    setQualifier(value:string){
        this.setState({qualifier:value});
        this.waitToSaveConfs();
    }

    /**
     * Set comment value.
     * @param value new comment value.
     */
    setComment(value:string){
        this.setState({comment:value});
        this.waitToSaveConfs();
    }

    /**
     * Set Dept Unit value.
     * @param value new Dept Unit value.
     */
    setDepthUnit(value:string){
        this.setState({depthUnit:value});
        this.waitToSaveConfs();
    }

    /**
     * Set Start Line value.
     * @param value new Start Line value.
     */
    setStartLine(value:number){
        this.setState({startLine:value});
        this.waitToSaveConfs();
    }

    /**
     * Set Dept Type value.
     * @param value new Dept Type value.
     */
    setDepthType(value:string){
        this.setState({depthType:value});
        this.waitToSaveConfs();
    }

    /**
     * Set No Value.
     * @param value new No Value.
     */
    setNoValue(value:string){
        this.setState({noValue:value});
        this.waitToSaveConfs();
    }

    /**
     * Create a timer to send updated values
     */
    waitToSaveConfs = () => {
        if(this.state.timerToSend != null){
          clearTimeout(this.state.timerToSend);
        }
        var timer = setTimeout(this.saveConf,2000);
        this.setState({timerToSend:timer});
    }

    /**
    * Perform save configs
    */
    saveConf = () => {
        const customEvent = new CustomEvent(this.updatedParserEvent, { detail: { 
            delimiter: this.state.delimiter,
            qualifier: this.state.qualifier,
            comment: this.state.comment,
            startLine: this.state.startLine,
            depthUnit: this.state.depthUnit,
            depthType: this.state.depthType,
            noValue: this.state.noValue,
            context:this.props.context
        } });
        document.dispatchEvent(customEvent);
    }

    render(){
        return(
            <>
                <div className='row'>
                    <div className='offset 1 col-4'>
                        <label>
                            Delimiter &nbsp;
                            <select className='col-8' name="delimiter" value={this.state.delimiter} onChange={(event)=>{this.setDelimiter(event.target.value)}}>
                                {
                                    this.props.options.delimiter.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.delimiter==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </label>
                    </div>
                    <div className='offset 1 col-4'>
                        <label>
                            Qualifier &nbsp;
                            <select className='col-8' name="qualifier" value={this.state.qualifier} onChange={(event)=>{this.setQualifier(event.target.value)}}>
                                {
                                    this.props.options.qualifier.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.qualifier==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </label>
                    </div>
                    <div className='offset 1 col-4'>
                        <label>
                            Start Line &nbsp;
                            <input className='col-8' type="number" min="1" value={this.state.startLine} onChange={(event)=>{if (parseInt(event.target.value) < 1) return false; this.setStartLine(parseInt(event.target.value))}} />
                        </label>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='offset 1 col-4'>
                        <label>
                            Comment &nbsp;
                            <select className='col-8' name="Comment" value={this.state.comment} onChange={(event)=>{this.setComment(event.target.value)}}>
                                {
                                    this.props.options.comment.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.comment==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </label>
                    </div>
                    <div className='offset 1 col-4'>
                        <label>
                            Distance Unit &nbsp;
                            <select name="Distance" value={this.state.depthUnit} onChange={(event)=>{this.setDepthUnit(event.target.value)}}>
                                {
                                    this.props.options.depthUnit.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.depthUnit==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </label>
                    </div>
                    <div className='offset 1 col-4'>
                        <label>
                            Depth &nbsp;
                            <select className='col-8' name="Depth" value={this.state.depthType} onChange={(event)=>{this.setDepthType(event.target.value)}}>
                                {
                                    this.props.options.depthType.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.depthType==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </label>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='offset 1 col-5'>
                        <label>
                            No Value &nbsp;
                            <select name="noValue" value={this.state.noValue} onChange={(event)=>{this.setNoValue(event.target.value)}}>
                                {
                                    this.props.options.noValue.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.noValue==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </label>
                    </div>
                </div>
            </>
        )
    }

}

export default ParserFieldsComponent;