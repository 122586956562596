//External packages
import React, { Component } from 'react';
import Switch from '@mui/material/Switch';
import isEqual from 'lodash.isequal';

// Internal Packages
import { StandartColors } from '../../../../../utils/enums/StandartColors';
import { wellMetadata } from '../../../../../services/wellService';
import { CorrelationTabEvents } from '../../../../../utils/enums/CorrelationsTab';
import { getUserConfs, saveUserConfs } from '../../../../../services/projectService';

interface PropertiesSet{
    name:String,
    propertiesNames: String[]
}

interface PropertiesCorrelationTabState {
    properties: String[],
    propertiesSelected: String[],
    wellsByProperties: {},
    propertiesSets:PropertiesSet[],
    currentPropertySet: number,
    selectedAll:boolean,
    newPropertySetName:string,
    timerToSend:any,
    prevState:any,
    metadata:any,
}

interface PropertiesCorrelationTabProps {
    wellsSelected:string[]
}

class PropertiesCorrelationsTab extends Component<PropertiesCorrelationTabProps, PropertiesCorrelationTabState> {

    propertyUpdateEvent: string = CorrelationTabEvents.propertyUpdate;

    constructor(props:any){
        super(props);
        this.state = {
            properties: [],
            propertiesSelected: [],
            wellsByProperties: {},
            propertiesSets:[{name:"All Properties", propertiesNames: []}],
            currentPropertySet:0,
            selectedAll:false,
            newPropertySetName:"",
            timerToSend:null,
            prevState:JSON.parse(JSON.stringify(this.props)),
            metadata:{}
        }
        
        
    }

    updateWells = () =>{
        const well_metadata = wellMetadata()
        let logNames:any[] = []
        let propertiesWells: any = {}
        well_metadata.then((metadata)=>{
            Object.keys(metadata).forEach((wellName:any, idx:any) => {
                if(metadata[wellName].curves){
                    metadata[wellName].curves.versions.forEach((version:any,version_idx:any)=>{
                        Object.keys(version.curves_info).forEach((logName:any,logIdx:any)=>{
                            if(!logNames.includes(logName) && logName != 'MD'){
                                logNames.push(logName);
                            }
                        });
                    });
                }
            });  

            propertiesWells = this.getNumberWellsByProperty(metadata);

                            
            this.setState({properties:logNames, metadata:metadata},()=>{
                this.setState({wellsByProperties:propertiesWells});
                this.selectAll(true);
                this.setTimerToSendChanges();
                this.loadPropertiesSets();
            });
        });
    }

    componentDidMount(): void {
        this.updateWells();
    }

    componentDidUpdate(prevProps: Readonly<PropertiesCorrelationTabProps>, prevState: Readonly<PropertiesCorrelationTabState>, snapshot?: any): void {
        let update = false;
        if(!isEqual(this.props, this.state.prevState)){
            update = true;
        }
        if(update){
            this.setState({prevState:JSON.parse(JSON.stringify(this.props))},()=>{
                let propertiesWells = this.getNumberWellsByProperty(this.state.metadata);
                this.setState({wellsByProperties:propertiesWells});
            })
        }

    }

    /**
     * Save property set.
     */
    savePropertySet = () =>{
        if(this.state.newPropertySetName !== ""){
            saveUserConfs([{"topic":"sets", "group":"property", "key": `${this.state.newPropertySetName}`, "value":this.state.propertiesSelected.toString()}]).then((response)=>{
                this.loadPropertiesSets()
            });
        }
        else{
            alert("Well set name field cannot set empty");
        }
    }

    /**
     * Load Properties sets from User's config
     */
    loadPropertiesSets = () =>{
        const projectConf = getUserConfs();
        const allWells = JSON.parse(JSON.stringify(this.state.properties))
        projectConf.then((response)=>{
            if(response.sets){
                const sets = response.sets;
                var propertiesSets:PropertiesSet[] = [{name:"All Properties", propertiesNames: allWells}];
                if(sets.property){
                    Object.keys(sets.property).forEach((propertySet,setIdx)=>{
                        propertiesSets.push({ name:propertySet, propertiesNames:sets.property[propertySet].split(",") })
                    });
                }
                this.setState({propertiesSets:propertiesSets},()=>{
                    // this.changeWellSet(this.state.currentWellSet)
                });
            }
        });
    }

    /**
     * 
     * @param propertySet 
     */
    changePropertySet = (propertySet:number) =>{
        let selectedAll = this.state.propertiesSets[propertySet].propertiesNames.length == this.state.properties.length;
        const propertiesNames = JSON.parse(JSON.stringify(this.state.propertiesSets[propertySet].propertiesNames))
        this.setState({currentPropertySet:propertySet, propertiesSelected:propertiesNames, selectedAll:selectedAll},()=>{
            this.sendConfs()
        });
    }

    checkOption =(event:any, check_name:string) =>{
        let checked = event.target.checked;
        let properties = this.state.propertiesSelected;
        if(checked){
            properties.push(check_name);
        }
        else{
            properties.splice(properties.indexOf(check_name),1);
        }
        let selectedAll = this.state.propertiesSelected.length == this.state.properties.length;
        this.setState({propertiesSelected:properties, selectedAll:selectedAll});
        this.setTimerToSendChanges()
    }

    selectAll = (selected:boolean) =>{
        if(selected){
            let well_names = JSON.parse(JSON.stringify(this.state.properties));
            this.setState({propertiesSelected:well_names, selectedAll:true});
        }
        else{
            this.setState({propertiesSelected:[], selectedAll:false});
        }
        this.setTimerToSendChanges();
    }

    setTimerToSendChanges = () => {
        if(this.state.timerToSend != null){
            clearTimeout(this.state.timerToSend);
          }
          var timer = setTimeout(this.sendConfs,100);
          this.setState({timerToSend:timer});
    }

    sendConfs = () =>{
        const customEvent = new CustomEvent(this.propertyUpdateEvent, { detail: {
            properties:this.state.propertiesSelected,
            currentPropertySet: this.state.currentPropertySet
        } 
        });
        document.dispatchEvent(customEvent);
    }

    showPropertiesNames = () =>{
        return(
            <>
                {/* {this.state.properties.map((log_name:any,idx:any)=>{
                    return (<div className='col-3'>
                        <label><input type='checkbox' onChange={(event)=>{this.checkOption(event, log_name);}} checked={this.state.propertiesSelected.includes(log_name)}/>{log_name}</label>
                    </div>);
                })} */}
                {
                    Object.entries(this.state.wellsByProperties).map((prop:any[])=>{
                        if( prop[0] != 'MD' ){
                            return (<div className='col-3 mb-2'>
                                <label><input type='checkbox' onChange={(event)=>{this.checkOption(event, prop[0]);}} checked={this.state.propertiesSelected.includes(prop[0])}/> {prop[0]} ({prop[1].length > 1 ? prop[1].length + ' wells': prop[1].length + ' well'})</label>
                            </div>);
                        }
                    })  
                }
            </>
        )
    }

    changeNewPropertySetName = (event:any) => {
        this.setState({newPropertySetName:event.target.value});
    }

    getNumberWellsByProperty = (metadata:any) =>{
        let wellsByProperty: any = {}

        Object.keys(metadata).forEach((wellName:any, idx:any) => {
            if(metadata[wellName].curves){
                Object.keys(metadata[wellName].curves.versions[0].curves_info).forEach((property:any,logIdx:any)=>{
                    if (!wellsByProperty[property]){
                        wellsByProperty[property] = []
                    }
                    if(this.props.wellsSelected.includes(wellName)){
                        wellsByProperty[property].push(wellName)
                    }
                });
            }
        });

        return wellsByProperty
    }

    render(){
        return(
            <>
                <div className='row'>
                    <div className='col-5'>
                        <label style={{display:"grid"}}>
                            <b>Select the properties to correlate:</b>
                            <span>
                                From: <select className="mt-2" id="wellSet" style={{width:"40%"}} onChange={(event)=>{this.changePropertySet(parseInt(event.target.value))}}>
                                { this.state.propertiesSets.map( (propertySet,setIdx)=>{
                                        return( <option value={setIdx} defaultChecked={setIdx==0}>{propertySet.name}</option> )
                                    } ) }
                                </select>
                            </span>
                        </label>
                    </div>
                </div>
                <div className='row mt-3 mb-2'>
                    <div className='offset-1 col-10' style={{backgroundColor:StandartColors.LightGray, borderRadius:"20px"}}>
                        <div className='row mt-3'>
                            <div className='col-5'>
                                <b>Select the propertie/s from {this.state.propertiesSets[this.state.currentPropertySet].name}:</b>
                                Select/Deselect all
                                <div className="onoffswitch">                  
                                    <input type="checkbox" 
                                        name="selectAllProperties" 
                                        className="onoffswitch-checkbox" 
                                        id="selectAllProperties" 
                                        checked={this.state.selectedAll} 
                                        onChange={(event)=>{this.selectAll(event.target.checked)}} 
                                    />
                                    <label className="onoffswitch-label" htmlFor="selectAllProperties"></label>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            {this.showPropertiesNames()}
                        </div>
                    </div>
                </div>
                <div className="row mt-3 p-2">
                    <div className='offset-6 col-4'>
                        <div className='fieldset'>
                            <label style={{display:"grid"}} className='legend'> Save as a property set (optional):
                            <input onChange={(event:any) => {this.changeNewPropertySetName(event)}} value={this.state.newPropertySetName} />
                            </label>
                        </div>
                    </div>
                    <div className='col-2'>
                        <input type="button" value="Save" className='save-button' onClick={()=>{this.savePropertySet()}} />
                    </div>
                </div>
            </>
        )
    }
}
export default PropertiesCorrelationsTab;